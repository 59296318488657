
import Vue, { PropType } from "vue";
import { SubPlot } from "@/lib/models/plot";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    subPlots: Array as PropType<SubPlot[]>,
  },
  methods: {
    onClickPlot(plotId) {
      this.$emit("click", plotId);
    },
  },
});

interface Props {
  subPlots: SubPlot[];
}

interface Data {}

interface Computed {}

interface Methods {
  onClickPlot(plotId: string): void;
}
