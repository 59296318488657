
import Vue from "vue";
import clone from "lodash/cloneDeep";
import Plots from "@/components/organisms/Plots.vue";
import PlotListVertical from "@/components/organisms/PlotListVertical.vue";
import CharacterList from "@/components/organisms/CharacterList.vue";
import WorldViewList from "@/components/organisms/WorldViewList.vue";
import Editor, { IEditorEmitArgs } from "@/components/molecules/Editor.vue";
import ManuscriptPreview from "@/components/organisms/ManuscriptPreview.vue";
import MultiManuscriptEditorFooter from "@/components/organisms/MultiManuscriptEditorFooter.vue";
import CharacterDetail from "@/components/organisms/CharacterDetail.vue";
import WorldViewDetail from "@/components/organisms/WorldViewDetail.vue";
import CharacterFolderList from "@/components/organisms/CharacterFolderList.vue";
import WorldViewFolderList from "@/components/organisms/WorldViewFolderList.vue";
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import {
  Plan,
  Manuscript,
  ManuscriptSetting,
  Plot,
  SubPlot,
  ShowFlags,
  Character,
  CharacterFolder,
  WorldView,
  WorldViewFolder,
} from "@/lib/models";
import EditorController from "@/components/molecules/EditorController.vue";
import MemoList from "@/components/organisms/MemoList.vue";
import MemoDetail from "@/components/organisms/MemoDetail.vue";

const contentMaxLenth = 100000;

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    Plots,
    PlotListVertical,
    CharacterList,
    WorldViewList,
    Editor,
    ManuscriptPreview,
    MultiManuscriptEditorFooter,
    CharacterDetail,
    WorldViewDetail,
    CharacterFolderList,
    WorldViewFolderList,
    EditorController,
    MemoList,
    MemoDetail,
  },
  props: {
    novelId: String,
    manuscriptKey: String,
  },
  data() {
    return {
      title: "",
      preTitle: "",
      content: "",
      preContent: "",
      saved: false,
      selectedPlotId: null,
      selectedCharacter: null,
      isShowCharacterFolderList: false,
      selectedCharacterFolderId: null,
      selectedCharacterFolder: null,
      selectedWorldView: null,
      isShowWorldViewFolderList: false,
      selectedWorldViewFolderId: null,
      selectedWorldViewFolder: null,
      selectedMemoId: null,
      styleGridMainArea: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 2.5fr",
      },
      styleGridPlot: {
        gridColumn: "1 / 2",
      },
      styleGridCharacters: {
        gridColumn: "2 / 3",
      },
      styleGridWorldViews: {
        gridColumn: "3 / 4",
      },
      styleGridMemo: {
        gridColumn: "unset",
      },
      styleGridManuscript: {
        gridColumn: "4 / 5",
      },
    };
  },
  async created() {
    const { $store, novelId, manuscript } = this;
    const { dispatch } = $store;

    dispatch("plotModule/initialize", novelId);
    dispatch("characterModule/initialize", novelId);
    dispatch("worldViewModule/initialize", novelId);
    dispatch("memoModule/initialize");

    const { title, content } = manuscript;
    this.title = title || "";
    this.preTitle = clone(this.title);
    this.content = content || "";
    this.preContent = clone(this.content);

    // set RecordWordCount
    this.$store.commit("manuscriptModule/setBaseWordCount", this.onlyCharacterWordCount);
  },
  mounted() {
    if (this.isShowPreview) {
      (this.$refs.footer as any).changedShowsFlagWithRadio();
    }
    this.onChageShowSettings();
  },
  computed: {
    manuscript() {
      const { manuscriptKey } = this;
      return this.$store.getters["manuscriptModule/manuscript"](manuscriptKey);
    },
    characters() {
      const characters = clone(this.$store.getters["characterModule/characterList"] as Character[]);

      const { selectedCharacterFolder } = this;
      try {
        if (selectedCharacterFolder) {
          characters.sort(
            (x, y) =>
              selectedCharacterFolder.characterKeys.indexOf(x.characterId) -
              selectedCharacterFolder.characterKeys.indexOf(y.characterId)
          );
        }
      } catch (error) {
        console.log(error);
      }

      return characters;
    },
    characterFolders() {
      return this.$store.getters["characterModule/characterFolderList"];
    },
    charactersCount() {
      const { characters } = this;

      return characters.length;
    },
    worldViews() {
      const worldViews = clone(this.$store.getters["worldViewModule/worldViewList"] as WorldView[]);

      const { selectedWorldViewFolder } = this;
      try {
        if (selectedWorldViewFolder) {
          worldViews.sort(
            (x, y) =>
              selectedWorldViewFolder.worldViewKeys.indexOf(x.worldViewId) -
              selectedWorldViewFolder.worldViewKeys.indexOf(y.worldViewId)
          );
        }
      } catch (error) {
        console.log(error);
      }

      return worldViews;
    },
    worldViewFolders() {
      return this.$store.getters["worldViewModule/worldViewFolderList"];
    },
    worldViewsCount() {
      const { worldViews } = this;

      return worldViews.length;
    },
    changed() {
      return this.preTitle !== this.title || this.preContent !== this.content;
    },
    contentLength() {
      let rubyLength = 0;
      const content = clone(this.content) as string;

      // eslint-disable-next-line no-control-regex
      const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
      const textWithRuby = content.match(rubyRegExp);
      if (textWithRuby) {
        textWithRuby.forEach((text) => {
          const onlyRubyRegExp = new RegExp("《.*?》$");
          const textOnlyRuby = text.match(onlyRubyRegExp);
          rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
        });
      }

      let contentLength = 0;
      switch (this.countType) {
        case "addBlank":
          contentLength = content.replace(/\n/g, "").length;
          break;
        case "addNewLine":
          contentLength = content.replace(/[^\S\n]/g, "").length;
          break;
        case "all":
          contentLength = content.length;
          break;
        case "onlyCharacter":
        default:
          contentLength = content.replace(/\s+|\n/g, "").length;
          break;
      }

      return contentLength - rubyLength;
    },
    pageCount() {
      const content = clone(this.content) as string;

      /** ルビを除外する */
      const splittedContent = content.split("\n");
      const removedRubyContent = splittedContent.map((content) => {
        // eslint-disable-next-line no-control-regex
        const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
        const textWithRuby = content.match(rubyRegExp);
        if (textWithRuby) {
          textWithRuby.forEach((text: string) => {
            let replaceText = text;
            const parentRegExp = new RegExp("[|｜]");
            replaceText = replaceText.replace(parentRegExp, "");
            replaceText = replaceText.replace("《.*?》", "");
            // eslint-disable-next-line no-param-reassign
            content = content.replace(text, replaceText);
          });
        }
        return content;
      });

      /** 原稿の設定情報を取得 */
      const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

      /** 行数を計算する */
      let lineCount = 0;
      removedRubyContent.forEach(
        (content) => (lineCount += content ? Math.ceil(content.length / wordCountOnVertical) : 1)
      );
      return Math.ceil(lineCount / lineCountPerPage);
    },
    onlyCharacterWordCount() {
      const content = clone(this.content) as string;

      let rubyLength = 0;
      // eslint-disable-next-line no-control-regex
      const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
      const textWithRuby = content.match(rubyRegExp);
      if (textWithRuby) {
        textWithRuby.forEach((text) => {
          const onlyRubyRegExp = new RegExp("《.*?》$");
          const textOnlyRuby = text.match(onlyRubyRegExp);
          rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
        });
      }

      const contentLength = content.replace(/\s+|\n/g, "").length;

      return contentLength - rubyLength;
    },
    countType() {
      return this.$store.getters["manuscriptModule/countType"];
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    showFlags() {
      return this.$store.getters["manuscriptModule/showFlags"];
    },
    isShowPlots() {
      const { showFlags } = this;
      return showFlags.isShowPlots;
    },
    isShowCharacters() {
      const { showFlags } = this;
      return showFlags.isShowCharacters;
    },
    isShowWorldViews() {
      const { showFlags } = this;
      return showFlags.isShowWorldViews;
    },
    isShowMemo() {
      const { showFlags } = this;
      return showFlags.isShowMemo;
    },
    isShowPreview() {
      return this.$store.getters["manuscriptModule/isShowPreviewOnManuscriptEditor"];
    },
    plot() {
      const { $store, selectedPlotId } = this;

      if (selectedPlotId !== "default") {
        return $store.getters["plotModule/subPlot"](selectedPlotId);
      }
      return $store.getters["plotModule/plots"];
    },
    subPlots() {
      const { $store } = this;
      return $store.getters["plotModule/subPlots"];
    },
    manuscriptList() {
      const { key } = this.manuscript as Manuscript;
      const { title, content } = this;
      const manuscriptObject: Manuscript = {
        key,
        title,
        content,
      };
      return [manuscriptObject];
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    saveTimeout() {
      return this.$store.getters["manuscriptModule/saveTimeout"];
    },
    autoSave() {
      return this.$store.getters["manuscriptModule/autoSave"];
    },
    isShowEditorController() {
      return this.$store.getters["manuscriptModule/isShowEditorController"];
    },
  },
  methods: {
    // マルチ執筆モード画面処理
    onChageShowSettings() {
      const { showFlags } = this;
      const { isShowPlots, isShowCharacters, isShowWorldViews, isShowMemo } = showFlags;

      // Gridのテンプレート設定
      const NumOfShowingSettings =
        Number(isShowPlots) + Number(isShowCharacters) + Number(isShowWorldViews) + Number(isShowMemo);
      const columnsWidth = NumOfShowingSettings < 3 ? 25 : 20;
      let gridTemplateColumnsValue = "";
      for (let i = 0; i < NumOfShowingSettings; i += 1) {
        gridTemplateColumnsValue += `${columnsWidth}% `; // プロット、登場人物、世界観、メモの表示幅
      }
      this.styleGridMainArea.gridTemplateColumns = `${gridTemplateColumnsValue}  1fr`; // 執筆の表示幅を追加

      let gridColumnPositionNum = 1;

      // プロットの表示部分操作
      if (isShowPlots) {
        this.styleGridPlot.gridColumn = `${gridColumnPositionNum} / ${(gridColumnPositionNum += 1)}`;
      }
      // 登場人物の表示部分操作
      if (isShowCharacters) {
        this.styleGridCharacters.gridColumn = `${gridColumnPositionNum} / ${(gridColumnPositionNum += 1)}`;
      }
      // 世界観の表示部分操作
      if (isShowWorldViews) {
        this.styleGridWorldViews.gridColumn = `${gridColumnPositionNum} / ${(gridColumnPositionNum += 1)}`;
      }
      // メモの表示部分操作
      if (isShowMemo) {
        this.styleGridMemo.gridColumn = `${gridColumnPositionNum} / ${(gridColumnPositionNum += 1)}`;
      }
      // 執筆部分の表示操作
      this.styleGridManuscript.gridColumn = `${gridColumnPositionNum} / ${(gridColumnPositionNum += 1)}`;

      if (this.isShowPreview) {
        this.styleGridManuscript.paddingLeft = "0px";
        this.styleGridManuscript.paddingRight = "0px";
      } else if (!isShowPlots && !isShowCharacters && !isShowWorldViews && !isShowMemo) {
        this.setManuscriptPaddingSize();
      } else {
        switch (NumOfShowingSettings) {
          default:
          case 2:
          case 3:
            this.styleGridManuscript.paddingLeft = "0px";
            this.styleGridManuscript.paddingRight = "0px";
            break;
          case 1:
            this.styleGridManuscript.paddingLeft = "200px";
            this.styleGridManuscript.paddingRight = "200px";
            break;
        }
      }
    },
    async onChangeTitle(args) {
      const { value, isComposing } = args;

      this.title = value;
      this.setModified(this.changed);

      if (this.changed && this.autoSave && !isComposing) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    async onChangeContent(args) {
      const { value, isComposing } = args;

      this.content = value;
      this.setModified(this.changed);

      if (this.changed && this.autoSave && !isComposing) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    onStartComposition() {
      if (this.changed && this.autoSave) {
        this.$store.dispatch("manuscriptModule/stopSaveTimeout");
      }
    },
    onEndComposition() {
      if (this.changed && this.autoSave) {
        this.$store.dispatch("manuscriptModule/startSaveTimeout");
      }
    },
    async save() {
      const { novelId, title, content, manuscriptKey, changed, contentLength } = this;

      if (!changed) return;

      if (contentLength > contentMaxLenth) {
        this.showErrorDialog(
          "本文の文字数が10万字を越えています。<br />10万字以内に編集して再度保存を実行してください。<br />※別の原稿にて続きをお書きください。"
        );
        return;
      }

      const key = manuscriptKey;
      this.$store.dispatch("manuscriptModule/updateManuscript", {
        novelId,
        key,
        title,
        content,
        callback: async () => {
          this.preTitle = clone(title);
          this.preContent = clone(content);
          this.setModified(false);

          this.saved = true;
          setTimeout(() => (this.saved = false), 2000);

          this.$notify({
            title: "保存しました",
            type: "success",
            duration: 2000,
          });
        },
      });
      this.$store.dispatch("manuscriptModule/incrementWritingCount", {
        novelId,
        value: this.onlyCharacterWordCount,
      });
    },
    async close() {
      this.$router.push({
        name: "editor",
        params: {
          novelId: this.novelId,
          manuscriptKey: this.manuscriptKey,
        },
      });
    },

    setManuscriptPaddingSize() {
      const paddingValue = `${Math.floor(window.innerWidth / 4.5)}px`; // 4.5はXD参考の比率から大体の値で設定
      // 画面幅でpadding変えないと執筆部分がやたら狭くなるので念のためリアルタイムで変化させる
      // this.$setだと反映されず、Object.assignを使ったら反映された。
      this.styleGridManuscript = Object.assign({}, this.styleGridManuscript, { paddingLeft: paddingValue });
      this.styleGridManuscript = Object.assign({}, this.styleGridManuscript, { paddingRight: paddingValue });
    },
    onClickPlotItem(plotId) {
      this.selectedPlotId = plotId;
    },
    onClickBackToPlotList() {
      this.selectedPlotId = null;
    },
    // 登場人物関係
    onClickCharacterItem(characterId) {
      // CharacterListの:click-function="onClickItem.bind(this,item.characterId)"でcharacterIdを渡している
      if (this.characters) {
        this.selectedCharacter = this.characters.find((c) => c.characterId === characterId) || null;
      }
    },
    onClickBackToCharacterList() {
      this.selectedCharacter = null;
    },
    onClickBackToCharacterFolderList() {
      this.isShowCharacterFolderList = true;
    },
    async onClickCharacterFolder(characterFolderId) {
      const { $store, novelId, characterFolders } = this;
      const { dispatch } = $store;

      await dispatch("characterModule/selectFolder", { novelId, characterFolderId });

      this.selectedCharacterFolder =
        characterFolders.find((folder) => folder.characterFolderId === characterFolderId) || null;
      this.isShowCharacterFolderList = false;
    },
    // 世界観関係
    onClickWorldViewItem(worldViewId) {
      // WorldViewListの:click-function="onClickItem.bind(this,item.worldViewId)"でworldViewIdを渡している
      if (this.worldViews) {
        this.selectedWorldView = this.worldViews.find((c) => c.worldViewId === worldViewId) || null;
      }
    },
    onClickBackToWorldViewList() {
      this.selectedWorldView = null;
    },
    async onClickWorldViewFolder(worldViewFolderId) {
      const { $store, novelId, worldViewFolders } = this;
      const { dispatch } = $store;

      await dispatch("worldViewModule/selectFolder", { novelId, worldViewFolderId });

      this.selectedWorldViewFolder =
        worldViewFolders.find((folder) => folder.worldViewFolderId === worldViewFolderId) || null;
      this.isShowWorldViewFolderList = false;
    },
    onClickBackToWorldViewFolderList() {
      this.isShowWorldViewFolderList = true;
    },
    onClickMemoItem(memoId) {
      this.selectedMemoId = memoId;
    },
    onClickBackToMemoList() {
      this.selectedMemoId = null;
    },
    showErrorDialog(message) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
    async restoreContent(content: string) {
      // contentに反映する前に、現在の変更を保存する
      if (this.changed) await this.save();

      this.content = content;
    },
  },
  watch: {
    showFlags() {
      this.onChageShowSettings();
    },
    saveTimeout() {
      if (this.saveTimeout) {
        this.save();
        this.$store.dispatch("manuscriptModule/stopSaveTimeout");
      }
    },
  },
});

interface Props {
  novelId: string;
  manuscriptKey: string;
}

interface Data {
  title: string;
  preTitle: string;
  content: string;
  preContent: string;
  saved: boolean;
  selectedPlotId: string | null;
  selectedCharacter: Character | null;
  isShowCharacterFolderList: boolean;
  selectedCharacterFolderId: string | null;
  selectedCharacterFolder: CharacterFolder | null;
  selectedWorldView: WorldView | null;
  isShowWorldViewFolderList: boolean;
  selectedWorldViewFolderId: string | null;
  selectedWorldViewFolder: WorldViewFolder | null;
  selectedMemoId: string | null;
  styleGridMainArea: StyleGridMainArea;
  styleGridPlot: StyleGrid;
  styleGridCharacters: StyleGrid;
  styleGridWorldViews: StyleGrid;
  styleGridMemo: StyleGrid;
  styleGridManuscript: StyleGrid;
}

interface Computed {
  manuscript: Manuscript;
  characters: Character[];
  characterFolders: CharacterFolder[];
  charactersCount: number;
  worldViews: WorldView[];
  worldViewFolders: WorldViewFolder[];
  worldViewsCount: number;
  changed: boolean;
  contentLength: number;
  pageCount: number;
  onlyCharacterWordCount: number;
  countType: string;
  plan: Plan;
  showFlags: ShowFlags;
  isShowPlots: boolean;
  isShowCharacters: boolean;
  isShowWorldViews: boolean;
  isShowMemo: boolean;
  isShowPreview: boolean;
  plot: Plot | SubPlot;
  subPlots: SubPlot[];
  manuscriptList: Manuscript[];
  manuscriptSetting: ManuscriptSetting;
  theme: string;
  saveTimeout: boolean;
  autoSave: boolean;
  isShowEditorController: boolean;
}

interface Methods {
  onChageShowSettings: () => void;
  onChangeTitle: (args: IEditorEmitArgs) => void;
  onChangeContent: (args: IEditorEmitArgs) => void;
  onStartComposition(): void;
  onEndComposition(): void;
  save: () => void;
  close: () => void;
  setManuscriptPaddingSize: () => void;
  onClickPlotItem: (plotId: string) => void;
  onClickBackToPlotList: () => void;
  onClickCharacterItem: (characterId: string) => void;
  onClickBackToCharacterList: () => void;
  onClickBackToCharacterFolderList: () => void;
  onClickCharacterFolder: (characterFolderId: string) => void;
  onClickWorldViewItem: (worldViewId: string) => void;
  onClickBackToWorldViewList: () => void;
  onClickWorldViewFolder: (worldViewFolderId: string) => void;
  onClickBackToWorldViewFolderList: () => void;
  onClickMemoItem: (memoId: string) => void;
  onClickBackToMemoList: () => void;
  showErrorDialog: (message: string) => void;
  restoreContent: (content: string) => void;
}

interface StyleGridMainArea {
  display: string;
  gridTemplateColumns: string;
}

interface StyleGrid {
  gridColumn: string;
  paddingLeft?: string;
  paddingRight?: string;
}
