
import Vue from "vue";
import { Dialog } from "@/lib/utils";
import RubyDialog, { RubyDialogProps } from "@/components/ui/RubyDialog.vue";
import EmphasisMarkDialog, { EmphasisMarkDialogProps } from "@/components/ui/EmphasisMarkDialog.vue";
import SearchReplaceDialog from "@/components/ui/SearchReplaceDialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    SearchReplaceDialog,
  },
  computed: {
    textareaElement() {
      return document.getElementById("textarea") as HTMLTextAreaElement;
    },
    enabledSearchReplace() {
      return this.$store.getters["manuscriptModule/enabledSearchReplace"];
    },
  },
  methods: {
    brackets() {
      const { textareaElement } = this;
      textareaElement.focus();
      const { selectionStart } = textareaElement;
      const selectedText = window.getSelection()!.toString();
      document.execCommand("insertText", false, `「${selectedText}」`);

      // NOTE: 文字選択をしていない場合、括弧の間にカーソルを移動
      if (!selectedText) {
        textareaElement.selectionStart = selectionStart + 1;
        textareaElement.selectionEnd = selectionStart + 1;
      }
    },
    ellipsis() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "……");
    },
    space() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "　");
    },
    dash() {
      const { textareaElement } = this;
      textareaElement.focus();
      document.execCommand("insertText", false, "――");
    },
    async ruby() {
      const { textareaElement } = this;
      textareaElement.focus();
      const selectedText = window.getSelection()!.toString();

      const rubyDialog = new Dialog(RubyDialog);
      const data: RubyDialogProps = {
        selectedText,
        negative: "キャンセル",
        positive: "入力する",
      };
      const result = await rubyDialog.show(data);

      textareaElement.focus();

      if (!result) return;
      document.execCommand("insertText", false, result);
    },
    async emphasisMark() {
      const { textareaElement } = this;
      textareaElement.focus();
      const selectedText = window.getSelection()!.toString();

      const emphasisMarkDialog = new Dialog(EmphasisMarkDialog);
      const data: EmphasisMarkDialogProps = {
        selectedText,
        negative: "キャンセル",
        positive: "入力する",
      };
      const result = await emphasisMarkDialog.show(data);

      textareaElement.focus();

      if (!result) return;
      document.execCommand("insertText", false, result);
    },
    toggleEnabledSearchReplace() {
      const { $store, enabledSearchReplace } = this;
      $store.dispatch("manuscriptModule/toggleEnabledSearchReplace");

      if (!enabledSearchReplace) {
        const selectedText = window.getSelection()!.toString();
        if (selectedText) $store.dispatch("manuscriptModule/updateSearchText", selectedText);
      }
    },
    undo() {
      document.execCommand("undo", false, undefined);
    },
    redo() {
      document.execCommand("redo", false, undefined);
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  textareaElement: HTMLTextAreaElement;
  enabledSearchReplace: boolean;
}

interface Methods {
  brackets(): void;
  ellipsis(): void;
  space(): void;
  dash(): void;
  ruby(): void;
  emphasisMark(): void;
  toggleEnabledSearchReplace(): void;
  undo(): void;
  redo(): void;
}
