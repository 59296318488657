
import Vue from "vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import RadioIcon from "@/components/atoms/RadioIcon.vue";
import TextVerticalIcon from "@/components/atoms/TextVerticalIcon.vue";
import DropupMenu, { Item } from "@/components/molecules/DropupMenu.vue";
import HamburgerMenu from "@/components/molecules/HamburgerMenu.vue";
import { Dialog } from "@/lib/utils";
import ManuscriptSettingsDialog, { ManuscriptSettingsDialogProps } from "@/components/ui/ManuscriptSettingsDialog.vue";
import ColorThemeDialog from "@/components/ui/ColorThemeDialog.vue";
import VersionsDialog, { VersionsDialogProps } from "@/components/ui/VersionsDialog.vue";
import { Plan, ManuscriptSetting, Manuscript, Theme, CountType, ShowFlags } from "@/lib/models";

const Flags = {
  /** プロット */
  plots: "plots",
  /** 登場人物 */
  characters: "characters",
  /** 世界観 */
  worldViews: "worldViews",
  /** メモ */
  memo: "memo",
} as const;
// eslint-disable-next-line no-redeclare
export type Flags = typeof Flags[keyof typeof Flags];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CheckboxIcon, RadioIcon, TextVerticalIcon, DropupMenu, HamburgerMenu },
  props: {
    novelId: String,
    manuscriptKey: String,
    content: String,
    changed: Boolean,
    contentLength: Number,
    pageCount: Number,
    saved: Boolean,
  },
  data() {
    const { onSelectColorTheme, onSelectCountType, showManuscriptSettings, showColorTheme, showVersions } = this as any;

    const colorThemeList: Item[] = [
      {
        name: "ノーマル",
        value: "default",
        callback() {
          onSelectColorTheme(this.value);
        },
      },
      {
        name: "ダークモード",
        value: "dark",
        callback() {
          onSelectColorTheme(this.value);
        },
      },
      {
        name: "セピアモード",
        value: "sepia",
        callback() {
          onSelectColorTheme(this.value);
        },
      },
      {
        name: "黒板モード",
        value: "blackboard",
        callback() {
          onSelectColorTheme(this.value);
        },
      },
      {
        name: "太字モード",
        value: "bold",
        callback() {
          onSelectColorTheme(this.value);
        },
      },
    ];

    const countTypeList: Item[] = [
      {
        name: "全文字数",
        value: "onlyCharacter",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白を含む全文字数",
        value: "addBlank",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "改行を含む全文字数",
        value: "addNewLine",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白/改行を含む全文字数",
        value: "all",
        callback() {
          onSelectCountType(this.value);
        },
      },
    ];

    const hamburgerMenuItems: Item[] = [
      {
        name: "過去の執筆履歴",
        icon: "history",
        callback() {
          showVersions();
        },
      },
      {
        name: "原稿用紙の設定",
        icon: "note-text-outline",
        callback() {
          showManuscriptSettings();
        },
      },
      {
        name: "カラーテーマ選択",
        icon: "palette",
        callback() {
          showColorTheme();
        },
      },
    ];

    return {
      checkBoxColor: "#707070",
      colorThemeList,
      countTypeList,
      hamburgerMenuItems,
    };
  },
  methods: {
    changedShowsFlagWithRadio() {
      const { selectedRadio } = this;
      let showFlags: ShowFlags;

      if (selectedRadio === "") {
        const isShowPlots = false;
        const isShowCharacters = false;
        const isShowWorldViews = false;
        const isShowMemo = false;
        showFlags = {
          isShowPlots,
          isShowCharacters,
          isShowWorldViews,
          isShowMemo,
        };
      } else {
        const isShowPlots = selectedRadio === "plots";
        const isShowCharacters = selectedRadio === "characters";
        const isShowWorldViews = selectedRadio === "worldviews";
        const isShowMemo = selectedRadio === "memo";
        showFlags = {
          isShowPlots,
          isShowCharacters,
          isShowWorldViews,
          isShowMemo,
        };
      }
      this.$store.dispatch("manuscriptModule/updateShowFlags", showFlags);
    },
    onClickSave() {
      this.$emit("save");
    },
    onClickCloseButton() {
      this.$emit("close");
    },
    onSelectColorTheme(themeValue) {
      this.$store.dispatch("manuscriptModule/selectTheme", themeValue);
    },
    onSelectCountType(countTypeValue) {
      this.$store.dispatch("manuscriptModule/selectCountType", countTypeValue);
    },
    showManuscriptSettings() {
      const { novelId, plan } = this;
      const manuscriptSettingsDialog = new Dialog(ManuscriptSettingsDialog);
      const data: ManuscriptSettingsDialogProps = {
        novelId,
        isChangeable: Boolean(plan),
        positive: "保存する",
        positiveCallback: async () => {},
      };
      manuscriptSettingsDialog.show(data);
    },
    onClickPreview() {
      this.changedShowsFlagWithRadio();
      this.$store.dispatch("manuscriptModule/switchPreviewOnManuscriptEditor");
    },
    onChangeShowFlags(flag) {
      let { isShowPlots, isShowCharacters, isShowWorldViews, isShowMemo } = this;
      const { isShowPreview } = this;

      switch (flag) {
        case Flags.plots:
          if (isShowPreview) {
            isShowPlots = true;
            isShowCharacters = false;
            isShowWorldViews = false;
            isShowMemo = false;
          } else {
            isShowPlots = !isShowPlots;
          }
          break;
        case Flags.characters:
          if (isShowPreview) {
            isShowPlots = false;
            isShowCharacters = true;
            isShowWorldViews = false;
            isShowMemo = false;
          } else {
            isShowCharacters = !isShowCharacters;
          }
          break;
        case Flags.worldViews:
          if (isShowPreview) {
            isShowPlots = false;
            isShowCharacters = false;
            isShowWorldViews = true;
            isShowMemo = false;
          } else {
            isShowWorldViews = !isShowWorldViews;
          }
          break;
        case Flags.memo:
          if (isShowPreview) {
            isShowPlots = false;
            isShowCharacters = false;
            isShowWorldViews = false;
            isShowMemo = true;
          } else {
            isShowMemo = !isShowMemo;
          }
          break;
        default:
          isShowPlots = false;
          isShowCharacters = false;
          isShowWorldViews = false;
          isShowMemo = false;
          break;
      }

      const showFlags = {
        isShowPlots,
        isShowCharacters,
        isShowWorldViews,
        isShowMemo,
      };
      this.$store.dispatch("manuscriptModule/updateShowFlags", showFlags);
    },
    showColorTheme() {
      const colorThemeDialog = new Dialog(ColorThemeDialog);
      colorThemeDialog.show();
    },
    showVersions() {
      const { novelId, manuscriptKey, content } = this;
      const versionsDialog = new Dialog(VersionsDialog);
      const data: VersionsDialogProps = { novelId, manuscriptKey, content };
      versionsDialog.show(data);
    },
    toggleAutoSave() {
      this.$store.dispatch("manuscriptModule/toggleAutoSave");
    },
    toggleShowEditorController() {
      this.$store.dispatch("manuscriptModule/toggleShowEditorController");
    },
  },
  computed: {
    manuscript() {
      const { manuscriptKey } = this;
      return this.$store.getters["manuscriptModule/manuscript"](manuscriptKey);
    },
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    colorThemeName() {
      const themeValue = this.theme;
      const theme = (this.colorThemeList as Item[]).find((colorTheme) => colorTheme.value === themeValue);
      return theme ? theme.name : "";
    },
    countType() {
      return this.$store.getters["manuscriptModule/countType"];
    },
    countTypeName() {
      const countTypeValue = this.countType;
      const countType = (this.countTypeList as Item[]).find((countType) => countType.value === countTypeValue);
      const countTypeName = countType ? countType.name : "";

      /** 原稿の設定情報を取得 */
      const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

      return `${countTypeName}（縦${wordCountOnVertical}×横${lineCountPerPage}）`;
    },
    wordCountInfo() {
      const { contentLength, pageCount } = this;

      return `${contentLength}字（${pageCount}P換算）`;
    },
    isShowPreview() {
      return this.$store.getters["manuscriptModule/isShowPreviewOnManuscriptEditor"];
    },
    showFlags() {
      return this.$store.getters["manuscriptModule/showFlags"];
    },
    isShowPlots() {
      const { showFlags } = this;
      return showFlags.isShowPlots;
    },
    isShowCharacters() {
      const { showFlags } = this;
      return showFlags.isShowCharacters;
    },
    isShowWorldViews() {
      const { showFlags } = this;
      return showFlags.isShowWorldViews;
    },
    isShowMemo() {
      const { showFlags } = this;
      return showFlags.isShowMemo;
    },
    selectedRadio() {
      const { showFlags } = this;
      if (showFlags.isShowPlots) return "plots";
      if (showFlags.isShowCharacters) return "characters";
      if (showFlags.isShowWorldViews) return "worldviews";
      if (showFlags.isShowMemo) return "memo";
      return "";
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId);
    },
    saveStatus() {
      const { autoSave, saved } = this;

      if (autoSave) {
        return saved ? "自動保存しました" : "自動保存設定中";
      }
      return saved ? "保存しました" : "";
    },
    lastModified() {
      return `最終保存：${this.manuscript.lastModified || "-"}`;
    },
    autoSave() {
      return this.$store.getters["manuscriptModule/autoSave"];
    },
    isShowEditorController() {
      return this.$store.getters["manuscriptModule/isShowEditorController"];
    },
    hamburgerMenuTogglableItems() {
      const { autoSave, toggleAutoSave, isShowEditorController, toggleShowEditorController } = this;

      const hamburgerMenuTogglableItems: Item[] = [
        {
          name: "自動保存",
          icon: "content-save-outline",
          value: autoSave,
          callback() {
            toggleAutoSave();
          },
        },
        {
          name: "コントロールバーの表示",
          icon: "toolbox-outline",
          value: isShowEditorController,
          callback() {
            toggleShowEditorController();
          },
        },
      ];

      return hamburgerMenuTogglableItems;
    },
    canSelectFlag() {
      return (flag) => {
        const { isShowPlots, isShowCharacters, isShowWorldViews, isShowMemo } = this;
        let numOfShowingSettings: number;

        switch (flag) {
          case Flags.plots:
            numOfShowingSettings = Number(isShowCharacters) + Number(isShowWorldViews) + Number(isShowMemo);

            if (isShowPlots || numOfShowingSettings < 3) return true;
            return false;
          case Flags.characters:
            numOfShowingSettings = Number(isShowPlots) + Number(isShowWorldViews) + Number(isShowMemo);

            if (isShowCharacters || numOfShowingSettings < 3) return true;
            return false;
          case Flags.worldViews:
            numOfShowingSettings = Number(isShowPlots) + Number(isShowCharacters) + Number(isShowMemo);

            if (isShowWorldViews || numOfShowingSettings < 3) return true;
            return false;
          case Flags.memo:
            numOfShowingSettings = Number(isShowPlots) + Number(isShowCharacters) + Number(isShowWorldViews);

            if (isShowMemo || numOfShowingSettings < 3) return true;
            return false;
          default:
            return false;
        }
      };
    },
    restoredContent() {
      return this.$store.getters["manuscriptModule/restoredContent"];
    },
  },
  watch: {
    async restoredContent(value: string | null) {
      if (typeof value === "string") {
        this.$emit("restore", value);
        this.$store.dispatch("manuscriptModule/initializeRestoredContent");
      }
    },
  },
});

interface Props {
  novelId: string;
  manuscriptKey: string;
  content: string;
  changed: boolean;
  contentLength: number;
  pageCount: number;
  saved: boolean;
}

interface Data {
  checkBoxColor: string;
  colorThemeList: Item[];
  countTypeList: Item[];
  hamburgerMenuItems: Item[];
}

interface Computed {
  manuscript: Manuscript;
  theme: Theme;
  colorThemeName: string;
  countType: CountType;
  countTypeName: string;
  wordCountInfo: string;
  isShowPreview: boolean;
  showFlags: ShowFlags;
  isShowPlots: boolean;
  isShowCharacters: boolean;
  isShowWorldViews: boolean;
  isShowMemo: boolean;
  selectedRadio: string;
  plan: Plan;
  manuscriptSetting: ManuscriptSetting;
  saveStatus: string;
  lastModified: string;
  autoSave: boolean;
  isShowEditorController: boolean;
  hamburgerMenuTogglableItems: Item[];
  canSelectFlag(flag: Flags): boolean;
  restoredContent: string | null;
}

interface Methods {
  changedShowsFlagWithRadio(): void;
  onClickSave(): void;
  onClickCloseButton(): void;
  onSelectColorTheme(themeValue: Theme): void;
  onSelectCountType(countTypeValue: CountType): void;
  showManuscriptSettings(): void;
  onClickPreview(): void;
  onChangeShowFlags(flag: Flags | null): void;
  showColorTheme(): void;
  showVersions(): void;
  toggleAutoSave(): void;
  toggleShowEditorController(): void;
}
